import type { Motif, MotifComponentProps } from '@snapchat/snap-design-system-marketing';
import {
  ArcadiaBlack,
  ArcadiaGray,
  contrastBlackButtonVars,
  darkThemeHeaderVars,
  defaultMotif,
  Gray,
  mergeMotifs,
  MotifComponent,
  MotifScheme,
  Plain,
  Yellow,
} from '@snapchat/snap-design-system-marketing';

const blockVars: Partial<MotifComponentProps<MotifComponent.BLOCK>> = {
  '--block-title-desktop-font-size': '42px',
  '--block-title-desktop-font-stretch': 'normal',
  '--block-title-desktop-font-line-height': '56px',
  '--block-title-mobile-font-size': '32px',
};

/** Motif for the Arcadia Creative Studios websites. */
export const arcadiaMotif: Motif = mergeMotifs(defaultMotif, {
  name: 'Arcadia',
  [MotifScheme.DEFAULT]: {
    name: 'Arcadia Yellow background.',
    [MotifComponent.BLOCK]: blockVars,
  },
  [MotifScheme.SECONDARY]: {
    name: 'Arcadia Black background.',
    [MotifComponent.ROOT]: {
      '--background-color': ArcadiaBlack.V200,
      '--h5-desktop-font-size': '28px',
      '--h5-desktop-font-weight': '300',
      '--h5-mobile-font-size': '24px',
      '--h5-mobile-font-weight': '300',
      '--h6-desktop-font-size': '20px',
      '--p1-desktop-font-size': '24px',
      '--p1-desktop-font-line-height': '140%',
      '--p1-desktop-font-weight': '300',
      '--p2-desktop-font-size': '18px',
    },
    [MotifComponent.HEADER]: {
      '--global-header-bg-color': ArcadiaBlack.V200,
    },
    [MotifComponent.BLOCK]: {
      ...blockVars,
      '--block-title-color': Plain.WHITE,
    },
    [MotifComponent.FORM]: {
      '--form-input-box-shadow': 'none',
      '--form-input-desktop-font-weight': '400',
      '--form-input-mobile-font-weight': '400',
    },
  },
  [MotifScheme.TERTIARY]: {
    name: 'Arcadia White background.',
    [MotifComponent.BLOCK]: blockVars,
  },
  [MotifScheme.QUATERNARY]: {
    name: 'Arcadia Gray background. Dark Text',
    [MotifComponent.ROOT]: {
      '--background-color': ArcadiaGray.V300,
      '--foreground-color': Plain.BLACK,
    },
    [MotifComponent.BUTTON]: contrastBlackButtonVars,
    [MotifComponent.HEADER]: {
      ...darkThemeHeaderVars,
      '--global-header-bg-color': ArcadiaGray.V300,
    },
    [MotifComponent.BLOCK]: {
      ...blockVars,
      '--block-title-color': Yellow.V100,
    },
    [MotifComponent.FOOTER]: {
      '--footer-bg-color': Gray.V100,
    },
  },
});
